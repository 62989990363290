<template>
  <el-row justify="center"> 
    <el-col :md="18">
      <el-button type="primary" @click="$router.push({ name: 'orders' })">주문 리스트로 돌아가기</el-button>
      
      <el-card v-if="order" header="주문 내역">
        <p>{{ order.createdAt }}</p>
        <p>{{ order.user.firstName + ' ' + order.user.lastName }}</p>
        <p>{{ order.status }}</p>

        <el-table :data="order.items">
          <el-table-column prop="name" label="이름" />
          <el-table-column prop="price" label="가격" />
          <el-table-column prop="order_item.quantity" label="수량" />
        </el-table>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import OrderService from "../services/OrderService";

export default {
  name: "EditOrder",
  data() {
    return {
      loading: false,
      order: null
    }
  },
  async created() {
    const orderId = this.$route.params.id
    try {
      const response = await OrderService.get(orderId)
      console.log(response.data)
      this.order = response.data
    } catch (e) {
      console.log(e)
    }
  },
  methods: {
  },
};
</script>

<style>
</style>